import loadable from '@loadable/component';
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet-async';

import { Layout } from '@components/layout';
import { Description, NoIndex, title } from '@components/layout/SEO';
import { Loading } from '@components/loading';

const Address = loadable(() => import('../../../features/order/Address'));

const AddressPage = () => (
  <Layout>
    <Helmet>
      <title>お客様情報の入力 | {title}</title>
      {Description}
      {NoIndex}
    </Helmet>
    <Suspense fallback={<Loading />}>
      <Address />
    </Suspense>
  </Layout>
);

export default AddressPage;
